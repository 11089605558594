<template>
  <ftx-dialog
    dialog="reserveTimeslot"
    :position="isMobile ? 'right' : 'bottom'"
    maximized
    :beforeShow="openDialog"
    :onHide="onDialogHide"
    className="reserveTimeDialog"
  >
    <template v-slot:DialogContent>
      <q-card class="reserveTimeSlot">
        <div class="dialogHeader">
          <q-btn class="close" flat dense v-close-popup v-if="!isMobile">
            <SvgIcon icon="close" />
          </q-btn>
          <div class="container">
            <div class="row no-wrap">
              <div class="col">
                <h4 class="text-h5 text-black q-mt-none q-mb-md">
                  <q-btn v-if="isMobile" flat dense>
                    <SvgIcon
                      color="white"
                      className="q-mr-xs"
                      size="1rem"
                      icon="back-arrow-line"
                      v-close-popup
                    />
                  </q-btn>
                  Reserve a time slot
                  <a
                    href="javascript:void(0);"
                    @click="openDeliveryInfo"
                    class="text-white no-underline"
                    v-if="isMobile"
                  >
                    <q-icon
                      name="error_outline"
                      size="1.3rem"
                      class="q-ml-sm"
                    />
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div class="container reserveTimeMiddle">
          <q-card>
            <q-tabs
              v-model="tab"
              class="bg-grey-3 text-grey-9"
              active-color="primary"
              active-bg-color="white"
              indicator-color="primary"
              :switch-indicator="!isMobile"
              align="justify"
              no-caps
              @input="onTabChange"
            >
              <q-tab
                name="SP"
                label="Store Pickup"
                v-if="isSPSlotBookingRequired"
              />
              <q-tab
                name="SCD"
                label="Delivery"
                v-if="isSCDSlotBookingRequired"
              />
            </q-tabs>
            <q-tab-panels v-model="tab" animated>
              <q-tab-panel
                class="no-padding reserveTimeSlotMobile"
                name="SP"
                v-if="isSPSlotBookingRequired"
              >
                <div class="addressSelect">
                  <div class="row">
                    <CurrentLocation :location="location" />
                  </div>
                </div>

                <template>
                  <DaysList
                    :slots="availableSPSlots"
                    :activeIndex="SPActiveIndex"
                    :updateField="updateField"
                    type="SP"
                    :loading="loading"
                  />
                  <q-scroll-area
                    :thumb-style="scrollAreaThumbStyle"
                    :bar-style="scrollAreaBarStyle"
                    :visible="scrollAreaVisible"
                    class="reserve-scroll-area"
                  >
                    <AvailableSlotList
                      :slots="availableSPSlots"
                      :activeIndex="SPActiveIndex"
                      :selectionSlot="SPSelectedSlot"
                      type="SP"
                      :updateField="updateField"
                      :loading="loading"
                    />
                  </q-scroll-area>
                </template>
              </q-tab-panel>

              <q-tab-panel
                class="no-padding"
                name="SCD"
                v-if="isSCDSlotBookingRequired"
              >
                <!-- Change Address -->
                <AddressListDialog v-if="isMobile" fulfillmentCode="scd" />
                <AddressList
                  v-else
                  v-show="changeAddress"
                  :updateField="updateField"
                  fulfillmentCode="scd"
                />
                <!-- Change Address -->
                <ReserveAddAddress
                  v-if="!isMobile"
                  v-show="addAddress"
                  :updateField="updateField"
                />
                <div
                  class="reserveTimeSlotMobile"
                  v-show="!addAddress && !changeAddress"
                >
                  <div class="addressSelect">
                    <div class="row">
                      <CurrentLocation :location="location" />
                      <CurrentAddress
                        fulfillmentCode="scd"
                        :updateField="updateField"
                      />
                    </div>
                  </div>

                  <DaysList
                    :slots="availableSCDSlots"
                    :activeIndex="SCDActiveIndex"
                    type="SCD"
                    :updateField="updateField"
                    :loading="loading"
                  />
                  <q-scroll-area
                    :thumb-style="scrollAreaThumbStyle"
                    :bar-style="scrollAreaBarStyle"
                    :visible="scrollAreaVisible"
                    class="reserve-scroll-area"
                  >
                    <AvailableSlotList
                      :slots="availableSCDSlots"
                      :activeIndex="SCDActiveIndex"
                      :selectionSlot="SCDSelectedSlot"
                      type="SCD"
                      :updateField="updateField"
                      :loading="loading"
                    />
                  </q-scroll-area>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </div>
        <div class="container reserveTimeBottom">
          <div
            v-show="!changeAddress && !addAddress"
            :class="[
              'row wrap no-wrap-md items-center',
              isMobile ? '' : 'q-pt-md',
            ]"
          >
            <div
              class="col-12 col-sm-5 col-md-6 col-lg-7 q-pt-sm q-pb-md"
              v-if="!isMobile"
            >
              <a
                href="javascript:void(0);"
                @click="openDeliveryInfo"
                class="text-dark no-underline"
              >
                <q-icon name="error_outline" size="0.8rem" class="q-mr-sm" />
                <span class="inline-block text-underline">
                  Know more about fulfillment options
                </span>
              </a>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-5">
              <div class="row">
                <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
                  <div class="form-action">
                    <q-btn
                      :rounded="!isMobile"
                      outline
                      no-caps
                      unelevated
                      color="dark"
                      v-close-popup
                      label="Continue Shopping"
                      class="q-mt-sm full-width"
                      size="1rem"
                    />
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
                  <div class="form-action">
                    <q-btn
                      :rounded="!isMobile"
                      no-caps
                      unelevated
                      color="secondaryOnBody"
                      :label="submitBtnText"
                      class="q-mt-sm full-width"
                      size="1rem"
                      @click="BookSlot"
                      :loading="submitting"
                      :disable="
                        !(
                          (tab == 'SP' && SPSelectedSlot) ||
                          (tab == 'SCD' && SCDSelectedSlot)
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { convertArrayToObject, cartHasFulfillment } from 'src/utils'

const initialState = () =>
  new Object({
    tab: 'SP',
    availableSPSlots: [],
    availableSCDSlots: [],
    availableISSlots: [],
    lastSelectedSPDayIndex: null,
    lastSelectedSCDDayIndex: null,
    lastSelectedISDayIndex: null,
    SPActiveIndex: 0,
    SCDActiveIndex: 0,
    ISActiveIndex: 0,

    SPSelectedSlot: null,
    SCDSelectedSlot: null,
    ISSelectedSlot: null,

    changeAddress: false,
    addAddress: false,
    loading: false,
    submitting: false,
    option: 'sp',
  })

export default {
  props: {
    location: {
      type: Object,
      default: new Object(),
    },
  },
  components: {
    DaysList: () => import('./DaysList'),
    AvailableSlotList: () => import('./AvailableSlotList'),
    CurrentLocation: () => import('./CurrentLocation'),
    AddressList: () => import('./AddressList'),
    AddressListDialog: () => import('./AddressListDialog'),
    CurrentAddress: () => import('./CurrentAddress'),
    ReserveAddAddress: () => import('./ReserveAddAddress'),
  },
  computed: {
    properties() {
      return this.getDialogProperties('reserveTimeslot')
    },
    submitBtnText() {
      if (this.properties) {
        if (this.properties.bookType == 'checkout')
          return `Proceed To ${
            this.$route.name === 'Cart' ? 'Checkout' : 'Cart'
          }`
      }

      return 'Save and Continue'
    },
    isSPSlotBookingRequired() {
      return this.checkSlotBookingRequired('sp')
    },
    isSCDSlotBookingRequired() {
      return this.checkSlotBookingRequired('scd')
    },
    ...mapGetters('reserveSlot', ['customerSPSlotId', 'customerSCDSlotId']),
    ...mapGetters('persisted', ['selectedFulfillmentCodeName']),
    ...mapGetters('common', [
      'isSlotBookingRequired',
      'checkSlotBookingRequired',
    ]),
    ...mapGetters('cart', ['currentCartDetails']),
  },
  methods: {
    async openDialog() {
      if (this.properties && this.properties.activeTab) {
        this.tab = this.properties.activeTab
      }

      let params = {}

      if (this.isSPSlotBookingRequired && !this.isSCDSlotBookingRequired) {
        params['slotType'] = 1
        this.tab = 'SP'
      } else if (
        !this.isSPSlotBookingRequired &&
        this.isSCDSlotBookingRequired
      ) {
        params['slotType'] = 2
        this.tab = 'SCD'
      }

      this.loading = true

      let data = await this.$store.dispatch(
        'reserveSlot/fetchAvailableSlot',
        params
      )

      let { storePickupSlots } = data.data,
        { scheduledDeliverySlots } = data.data

      if (storePickupSlots)
        storePickupSlots.forEach((slot, index) => {
          slot.availableSlots = convertArrayToObject(
            slot.availableSlots || [],
            'slotId'
          )

          if (
            this.customerSPSlotId &&
            slot.availableSlots[this.customerSPSlotId]
          )
            this.SPActiveIndex = index
        })

      if (scheduledDeliverySlots)
        scheduledDeliverySlots.forEach((slot, index) => {
          slot.availableSlots = convertArrayToObject(
            slot.availableSlots || [],
            'slotId'
          )

          if (
            this.customerSCDSlotId &&
            slot.availableSlots[this.customerSCDSlotId]
          )
            this.SCDActiveIndex = index
        })

      this.SPSelectedSlot = this.customerSPSlotId
      this.SCDSelectedSlot = this.customerSCDSlotId

      this.availableSPSlots = storePickupSlots
      this.availableSCDSlots = scheduledDeliverySlots

      this.loading = false
    },
    updateField(fieldName, val) {
      this[fieldName] = val
    },
    onTabChange(value) {
      this.changeAddress = false
      this.addAddress = false
    },
    async BookSlot() {
      let activeFulfillment = this.tab

      if (
        this[`${activeFulfillment}SelectedSlot`] &&
        this[`${activeFulfillment}SelectedSlot`] !=
          this[`customer${activeFulfillment}SlotId`]
      ) {
        let activeDayIndex = parseInt(
            this[`lastSelected${activeFulfillment}DayIndex`] != null
              ? this[`lastSelected${activeFulfillment}DayIndex`]
              : this[`${activeFulfillment}ActiveIndex`]
          ),
          currentDaySlot =
            this[`available${activeFulfillment}Slots`][activeDayIndex]
        let selectedSlot =
          currentDaySlot.availableSlots[
            this[`${activeFulfillment}SelectedSlot`]
          ]
        let isPaid = !!(selectedSlot && selectedSlot?.freeSlotCount <= 0)
        this.submitting = true

        let params = {
          slotID: this[`${activeFulfillment}SelectedSlot`],
          bookingDate: currentDaySlot.date,
          type: activeFulfillment,
          currentLocationID: this.location.locationId,
          isPaid: isPaid,
        }
        if (!this.isLoggedIn)
          params.orderNumber = this.currentCartDetails?.orderNumber

        await this.$store
          .dispatch('reserveSlot/bookOrUpdateSlot', params)
          .then((response) => {
            if (response && response.errors && response.errors.length > 0)
              this.showError(response.errors[0].descreption)
          })
          .finally(() => {
            this.submitting = false
          })
      }

      if (
        !this.customerSCDSlotId &&
        this.isSCDSlotBookingRequired &&
        cartHasFulfillment(this.currentCartDetails, 'scd')
      ) {
        this.tab = 'SCD'
        // this.scrollToPosition(this.$refs.scrollArea, 0, 100)
        return false
      } else if (
        !this.customerSPSlotId &&
        this.isSPSlotBookingRequired &&
        cartHasFulfillment(this.currentCartDetails, 'sp')
      ) {
        // this.scrollToPosition(this.$refs.scrollArea, 0, 100)
        this.tab = 'SP'
        return false
      }

      this.changeDialogState({
        dialog: 'reserveTimeslot',
        val: false,
      })

      if (this.properties.bookType == 'checkout') {
        return this.$router
          .push({
            name: this.$route.name !== 'Cart' ? 'Cart' : 'Checkout',
          })
          .catch(() => {})
      }
    },
    onDialogHide() {
      Object.assign(this.$data, initialState())
    },
    openDeliveryInfo() {
      this.changeDialogState({
        dialog: 'deliveryInfo',
        val: true,
        parentDialog: 'reserveTimeslot',
      })
      this.$root.$emit('selected-delivery-info', this.tab.toLocaleLowerCase())
    },
  },
  data: initialState,
}
</script>

<style lang="scss">
.reserveTimeSlot {
  padding: 0;
  position: relative;
  width: 100vw !important;
  .form-field,
  .form-action {
    padding-bottom: 16px;
  }
  .form-checkbox {
    font-size: 13px;
    color: #0f141a;
  }
  .dialogHeader {
    padding: 30px 0 30px;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      font-size: 8px;
    }
    h4.text-h5 {
      margin: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    @media (max-width: 1439px) {
      padding: 16px 0;
    }
    @media (max-width: 1023px) {
      padding: 15px 0;
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $white;
      color: var(--q-color-secondaryText) !important;
      position: relative;
      z-index: 9;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      h4.text-h5 {
        padding-right: 60px;
        font-size: 16px;
        color: #fff !important;
        text-align: left;
      }
      .close {
        width: 70px;
        height: 70px;
      }
    }
  }
  .container {
    max-width: 1024px;
    @media (min-width: 1440px) {
      max-width: 1140px;
    }
  }
  .q-tab {
    min-height: 50px;
    min-width: 50%;
    @media (min-width: 1440px) {
      min-height: 60px;
    }
  }
  .q-tab__label {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    @media (min-width: 1681px) {
      font-size: 18px;
    }
  }
  .q-panel-parent,
  .q-tab-panel {
    overflow: hidden;
  }
  .addressSelect {
    padding: 0;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    @media (max-width: 1023px) {
      div[class^='col'] {
        position: relative;
      }
    }
    h6 {
      font-size: 16px;
      color: #0f141a;
      margin: 0;
      padding: 0;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
      a {
        font-size: 12px;
        @media (max-width: 1023px) {
          position: absolute;
          top: 5px;
          right: 20px;
          display: block;
          margin: 0;
        }
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 22px;
      color: #888888;
      @media (max-width: 1023px) {
        margin-bottom: 5px;
        line-height: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    div[class^='col'] {
      padding: 20px;
      border-left: 1px solid #dadada;
      &:first-child {
        border-left: none;
      }
      @media (max-width: 1439px) {
        padding: 12px 20px;
      }
      @media (max-width: 1023px) {
        padding: 5px 10px;
        border: none;
      }
    }
  }
  .addressDays {
    border-bottom: 1px solid #dadada;
    ul {
      margin: 0;
      padding: 10px 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      li {
        padding: 5px;
        font-size: 16px;
        text-align: center;
        @media (min-width: 1681px) {
          padding: 10px;
          font-size: 18px;
        }
        a {
          display: block;
          color: #0f141a;
          text-decoration: none;
        }
        span {
          margin: 0 0;
          padding: 5px;
          display: block;
          font-size: 14px;
          line-height: 24px;
          color: #314154;
          background: #fafafa;
          border-radius: 10px;
          text-transform: uppercase;
          strong {
            display: block;
            font-weight: normal;
          }
          @media (min-width: 768px) {
            min-width: 80px;
          }
          @media (min-width: 1024px) {
            padding: 10px 15px;
            min-width: 100px;
            strong {
              font-size: 18px;
            }
          }
          @media (min-width: 1681px) {
            font-size: 14px;
            strong {
              font-size: 22px;
            }
          }
        }
        &.current {
          font-weight: bold;
          span {
            color: #fff;
            color: var(--q-color-secondaryText) !important;
            background: $secondary;
            background: var(--q-color-secondary) !important;
            font-weight: normal;
            transition: all 0.2s ease;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  .addressTime {
    .q-item {
      border-bottom: 1px solid #dadada;
      &:last-child {
        border-bottom: none;
      }
      @media (max-width: 1439px) {
        min-height: 36px;
        padding: 6px 16px;
        padding-right: 24px;
      }
    }
  }
  /* Schedule Address Dialog */
  .scheduleList {
    padding-bottom: 8px;
    .reserve-scroll-area {
      height: calc(100vh - 360px);
      max-width: 100%;
    }
    .addressBreadcrumb {
      padding-bottom: 15px;
      a,
      span {
        padding-right: 20px;
        font-size: 16px;
        color: #888888;
        position: relative;
        text-decoration: none;
        i {
          margin-right: 10px;
          font-size: 12px;
          top: -1px;
          position: relative;
        }
      }
      span {
        &:before {
          content: '/';
          position: absolute;
          left: -12px;
        }
      }
      a:hover {
        color: $secondary;
        color: var(--q-color-secondaryOnBody) !important;
      }
    }
    .addressSelect {
      padding: 0;
      border-bottom: 1px solid #dadada;
      h6 {
        font-size: 16px;
        color: #0f141a;
        margin: 0;
        padding: 0;
        @media (min-width: 1681px) {
          font-size: 20px;
        }
        a {
          font-size: 12px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 22px;
        color: #888888;
      }
      div[class^='col'] {
        padding: 20px;
        border: none;
        &:not(.addbtn) {
          border-left: 1px solid #dadada;
        }
        &:first-child {
          border-left: none;
        }
      }
    }
    .addressDays {
      border-bottom: 1px solid #dadada;
      ul {
        margin: 0;
        padding: 10px 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        li {
          padding: 10px;
          font-size: 18px;
          text-align: center;
          a {
            display: block;
            color: #0f141a;
            text-decoration: none;
          }
          span {
            margin: 5px 0;
            display: block;
            width: 40px;
            height: 40px;
            font-size: 16px;
            line-height: 40px;
            color: #314154;
            border-radius: 50%;
          }
          &.current {
            font-weight: bold;
            span {
              color: #fff;
              color: var(--q-color-secondaryText) !important;
              background: $secondary;
              background: var(--q-color-secondary) !important;
              font-weight: normal;
            }
          }
        }
      }
    }
    .addressTime {
      .q-item {
        border-bottom: 1px solid #dadada;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .searchAddress {
      padding: 25px 20px 0;
      .searchAddressList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        .q-item {
          padding: 10px;
          width: 100%;
          height: auto;
          @media (min-width: 600px) {
            width: 33.33%;
          }
          @media (min-width: 1024px) {
            width: 25%;
          }
          .q-item__section {
            justify-content: flex-start;
            position: relative;
            padding: 20px 20px;
            padding-bottom: 75px;
            height: 100%;
            background: #fff;
            border: 1px solid transparent;
            border-radius: 5px;
            .q-item__label {
              padding-bottom: 5px;
              font-size: 14px;
              line-height: 20px !important;
              color: #888888;
              &.title {
                font-size: 20px;
                color: #0f141a;
                span {
                  display: inline-block;
                  padding: 3px 10px;
                  margin: 3px;
                  font-size: 12px;
                  line-height: normal;
                  background: #91bf5b;
                  color: #fff;
                  border-radius: 12px;
                }
              }
            }
            &:hover {
              background: #f1fbff;
              border-color: #c4e7f5;
            }
            &.selected {
              background: #f1fbff;
              border-color: #c4e7f5;
            }
            .action {
              position: absolute;
              left: 20px;
              bottom: 25px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1681px) {
  }
  @media (max-width: 1023px) {
    .scheduleList {
      .addressDays ul {
        li {
          font-size: 13px;
          padding: 10px 1px;
          span {
            font-size: 12px;
            width: 30px;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
  /* Schedule Address Dialog */
  .reserveTimeMiddle {
    @media (max-width: 1023px) {
      .q-card {
        box-shadow: none;
      }
    }
    @media (max-width: 1023px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .reserveTimeBottom {
    a {
      font-size: 15px;
      @media (min-width: 1681px) {
        font-size: 17px;
      }
    }
    @media (max-width: 1023px) {
      padding-left: 0;
      padding-right: 0;
      .col-12:first-child {
        order: 2;
        text-align: center;
      }
    }
  }
}
@media (min-width: 1681px) {
}
@media (max-width: 1023px) {
  .reserveTimeBottom {
    padding: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
    position: relative;
    z-index: 2;
    border-radius: 10px 10px 0 0 !important;
    background-color: #fff;
    .form-action {
      padding-bottom: 8px;
    }
  }
  .reserveTimeSlot {
    padding: 0px;
    .q-tab {
      background: #fff;
      min-height: 50px;
    }
    .q-tab__label {
      font-size: 14px;
    }
  }
  .reserveTimeSlot {
    .addressDays ul {
      li {
        font-size: 13px;
        padding: 0px 1px;
        span {
          background: none;
          border-radius: 5px;
          font-size: 12px;
          line-height: 18px;
          strong {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .reserveTimeSlotMobile {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 178px) !important;
    .addressSelect {
      flex: 1 0 0;
    }
    .addressDays {
      flex: 1 0 0;
    }
    .reserve-scroll-area {
      flex: 1 1 auto;
      height: 100%;
    }
  }
}
@media (max-width: 374px) {
  .reserveTimeBottom {
    .q-btn {
      font-size: 11px !important;
    }
  }
}
.reserve-scroll-area {
  height: calc(80vh - 360px);
  max-width: 100%;
  @media (max-width: 1439px) {
    height: calc(90vh - 360px);
  }
  @media (max-width: 1023px) {
    height: calc(100vh - 430px);
  }
}
.rts-option {
  min-width: 100%;
  .q-item__section--avatar {
    min-width: 46px;
    padding-right: 0;
  }
}
</style>
